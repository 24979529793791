import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const RegChairFinals = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setregName] = useState("");
  const [grpName, setgrpName] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [meet, setmeet] = useState("");
  const [eminenceForm, seteminenceForm] = useState("");
  const [mangalyatra, setmangalyatra] = useState("");
  const [participation, setparticipation] = useState("");
  const [formAB, setformAB] = useState("");
  const [splMeet, setsplMeet] = useState("");
  const [attendance, setattendance] = useState("");
  const [boardMeet, setboardMeet] = useState("");
  const [groupsInc, setgroupsInc] = useState("");
  const [specSplmeet, setspecSplmeet] = useState("");
  const [hours, sethours] = useState("");

  const navigate = useNavigate();

  const [showFormModal, setShowFormModal] = useState(false);
  const [submittedData, setsubmittedData] = useState("");
  const handleModalClose = () => {
    navigate("/");
    setShowFormModal(false);
  };

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email;
    console.log(finalEmail);

    if (!regName || !grpName) {
      alert("Please Submit your Region Name, Group Name");
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("grpName", grpName);
    formField.append("name", name);
    formField.append("email", email);
    formField.append("meet", meet);
    formField.append("eminenceForm", eminenceForm);
    formField.append("mangalyatra", mangalyatra);
    formField.append("participation", participation);
    formField.append("formAB", formAB);
    formField.append("splMeet", splMeet);
    formField.append("attendance", attendance);
    formField.append("boardMeet", boardMeet);
    formField.append("groupsInc", groupsInc);
    formField.append("specSplmeet", specSplmeet);
    formField.append("hours", hours);

    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-regchair-finale-registrations/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setsubmittedData(response.data);
        setShowFormModal(true);
      })
      .catch(function(error) {
        console.log(error);
        alert(
          "We encountered an error processing your request. Please try again later"
        );
      });

    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const [sgnGroups, setSgnGroups] = useState([]);
  const fetchSgnGroups = async () => {
    const response = await axios.get(`/user/${regName}/sangini/`);
    setSgnGroups(response.data);
  };
  useEffect(() => {
    fetchSgnGroups();
  }, [regName]);

  return (
    <div>
      {/* <SecurePageSection
        accessableUsers={
          "admin,region,group,former,intDirector,offBearer,eminence,eminenceChair,sangini,sanginiGroup"
        }
      /> */}
      <SecurePageSection accessableUsers={"admin"} />
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence Finals - Region Chariman</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  maxlength="7000"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  maxlength="7000"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  maxlength="7000"
                  onChange={(e) => setregName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  value={grpName}
                  maxlength="7000"
                  onChange={(e) => setgrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() +
                        group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No. of total meeting in 21 month from apr 23 to dec 24.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="meet"
                  value={meet}
                  rows="3"
                  placeholder="No. of total meeting in 21 month from apr 23 to dec 24."
                  maxlength="7000"
                  onChange={(e) => setmeet(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No. or total eminance form filled in 6 quarters, reward if you
                  have won, how many
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="eminenceForm"
                  value={eminenceForm}
                  rows="3"
                  placeholder="No. or total eminance form filled in 6 quarters, reward if you have won, how many"
                  maxlength="7000"
                  onChange={(e) => seteminenceForm(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No. of report in mangalyatra out of 12 with specific page No.
                  & name
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="mangalyatra"
                  value={mangalyatra}
                  rows="3"
                  placeholder="No. of report in mangalyatra out of 12 with specific page No. & name"
                  maxlength="7000"
                  onChange={(e) => setmangalyatra(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No. of participation in i.e. 1,2,3,4,5 quarters 2 marks each
                  quarter
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="participation"
                  value={participation}
                  rows="3"
                  placeholder="No. of participation in i.e. 1,2,3,4,5 quarters  2 marks each quarter"
                  maxlength="7000"
                  onChange={(e) => setparticipation(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Submission of form A + B & online money transfer FROM TOTAL
                  GROUPS UR REGION HAVE
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="formAB"
                  value={formAB}
                  rows="3"
                  placeholder="Submission of form A + B & online money transfer FROM TOTAL GROUPS UR REGION HAVE"
                  maxlength="7000"
                  onChange={(e) => setformAB(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Specific meeting which has enhanced JSG bonding / JSG moment /
                  Suppotting society. (THIS MEETING SHLD NE INITIATED BY YOU AND
                  OTHER SHLD BE IN SUPPORT)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="splMeet"
                  value={splMeet}
                  rows="3"
                  placeholder="Specific meeting which has enhanced JSG bonding / JSG moment / Suppotting society. (THIS MEETING SHLD NE INITIATED BY YOU AND OTHER SHLD BE IN SUPPORT)"
                  maxlength="7000"
                  onChange={(e) => setsplMeet(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Your attandance is JSGIF if & region progam how many ? (HOW
                  MANY TIMES YOU HAVE REQUESTED OB'S OF JSGIF AND REGION TO
                  VISIT WITH YOU TO GROUP)
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="attendance"
                  value={attendance}
                  rows="3"
                  placeholder="Your attandance is JSGIF if & region progam how many ? (HOW MANY TIMES YOU HAVE REQUESTED OB'S OF JSGIF AND REGION TO VISIT WITH YOU TO GROUP)"
                  maxlength="7000"
                  onChange={(e) => setattendance(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  NO OF BOARD MEETINGS YOU HAVE ARRANGED IN LAST 21 MONTHS [OF
                  OFFFICIAL MEMBERS OF REGION]
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="boardMeet"
                  value={boardMeet}
                  rows="3"
                  placeholder="NO OF BOARD MEETINGS YOU HAVE ARRANGED IN LAST 21 MONTHS [OF OFFFICIAL MEMBERS OF REGION]"
                  maxlength="7000"
                  onChange={(e) => setboardMeet(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>NO OF GROUPS INCREASED IN YOUR TENURE</Form.Label>
                <Form.Control
                  as="textarea"
                  name="groupsInc"
                  value={groupsInc}
                  rows="3"
                  placeholder="NO OF GROUPS INCREASED IN YOUR TENURE"
                  maxlength="7000"
                  onChange={(e) => setgroupsInc(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  YOUR SPECIFIC SPECIAL MEETING YOU THINK IT IS VERY DIFFERENT
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="specSplmeet"
                  value={specSplmeet}
                  rows="3"
                  placeholder="YOUR SPECIFIC SPECIAL MEETING YOU THINK IT IS VERY DIFFERENT"
                  maxlength="7000"
                  onChange={(e) => setspecSplmeet(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  NO OF HOURS AND TRAVELLING DETAIL YOU EXPENDED ON JSG MOVEMENT
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="hours"
                  value={hours}
                  rows="3"
                  placeholder="NO OF HOURS AND TRAVELLING DETAIL YOU EXPENDED ON JSG MOVEMENT"
                  maxlength="7000"
                  onChange={(e) => sethours(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>
              }
            >
              <Button
                style={{ marginTop: "30px" }}
                className="btn--outline--dark"
                disabled
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
        <Modal centered show={showFormModal}>
          <Modal.Header>
            <Modal.Title>Form Submitted</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p>
              You have successfully Registered. Your application ID is :
              {submittedData?.id}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn--four" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default RegChairFinals;
