import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
  Image,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import JSGIF_Qr from "../../images/JSGIF_Qr.jpg";

const RegisterFinale = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setRegName] = useState("");
  const [grpName, setGrpName] = useState("");
  const [numperRoom, setnumperRoom] = useState(1);

  const initMembers = [
    {
      name: "",
      aadhar: "",
      mobile: "",
      email: "",
      pic: null,
    },
  ];

  const [members, setmembers] = useState(initMembers);
  const [amt, setamt] = useState(70000);
  const [payDate, setpayDate] = useState("");
  const [payUTR, setpayUTR] = useState("");
  const [payment, setpayment] = useState(null);

  const navigate = useNavigate();

  const [showFormModal, setShowFormModal] = useState(false);
  const [submittedData, setsubmittedData] = useState("");
  const handleModalClose = () => {
    navigate("/");
    setShowFormModal(false);
  };

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com,sapannahta@gmail.com" +
      members[0].email;
    console.log(finalEmail);
    console.log("members", members);

    if (!regName) {
      alert("Please submit your Region Name");
      setSubmitting(false);
      return;
    }

    if (!grpName) {
      alert("Please submit your Group Name");
      setSubmitting(false);
      return;
    }

    if (!numperRoom) {
      alert("Please specify the Number of members per room");
      setSubmitting(false);
      return;
    }

    if (!payDate) {
      alert("Please specify the Payment Date");
      setSubmitting(false);
      return;
    }

    if (!payUTR) {
      alert("Please specify the Payment UTR");
      setSubmitting(false);
      return;
    }

    if (!payment) {
      alert("Please Upload Payment Screenshot");
      setSubmitting(false);
      return;
    }

    for (let i = 0; i < numperRoom; i++) {
      const member = members[i];
      if (
        !member.name ||
        !member.email ||
        !member.mobile ||
        !member.pic ||
        !member.aadhar
      ) {
        alert(`Please specify all the details of Member ${i + 1}`);
        setSubmitting(false);
        return;
      }
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("grpName", grpName);
    formField.append("numperRoom", numperRoom);
    formField.append("payDate", payDate);
    formField.append("payUTR", payUTR);
    formField.append("amt", amt);

    if (payment !== null) {
      formField.append("payment", payment);
    }

    members.forEach((member, index) => {
      formField.append(`name${index + 1}`, member.name);
      formField.append(`aadhar${index + 1}`, member.aadhar);
      formField.append(`mobile${index + 1}`, member.mobile);
      formField.append(`email${index + 1}`, member.email);
      if (member.pic !== null) {
        formField.append(`pic${index + 1}`, member.pic);
      }
    });

    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-finale-registrations/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setsubmittedData(response.data);
        setShowFormModal(true);
      })
      .catch(function(error) {
        console.log(error);
        alert(
          "There was an error processing your request. Please try again using a different device or reduce the size of your images"
        );
      });

    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const grpresponse = await axios.get(`/user/${regName}/groups/`);
    const sgnresponse = await axios.get(`/user/${regName}/sangini/`);
    const combineGroup = [...grpresponse.data, ...sgnresponse.data];
    setGroups(combineGroup);
  };

  useEffect(() => {
    fetchGroups();
  }, [regName]);

  useEffect(() => {
    if (numperRoom === 1) {
      setamt(5000);
    } else if (numperRoom === 2) {
      setamt(8000);
    } else if (numperRoom === 3) {
      setamt(9000);
    }
  }, [numperRoom]);

  const addEvent = () => {
    if (members.length < 3) {
      setnumperRoom(Math.floor(numperRoom) + 1);
      setmembers([
        ...members,
        { name: "", mobile: "", aadhar: "", email: "", pic: null },
      ]);
    }
  };

  const removeMember = (index) => {
    const updatedmembers = [...members];
    updatedmembers.splice(index, 1);
    setmembers(updatedmembers);
    setnumperRoom(numperRoom - 1);
  };

  const handlenameChange = (index, value) => {
    const updatedmembers = [...members];
    updatedmembers[index].name = value;
    setmembers(updatedmembers);
  };

  const handleemailChange = (index, value) => {
    const updatedmembers = [...members];
    updatedmembers[index].email = value;
    setmembers(updatedmembers);
  };

  const handlemobileChange = (index, value) => {
    const updatedmembers = [...members];
    updatedmembers[index].mobile = value;
    setmembers(updatedmembers);
  };

  const handleaadharChange = (index, value) => {
    const updatedmembers = [...members];
    updatedmembers[index].aadhar = value;
    setmembers(updatedmembers);
  };

  const handlepicChange = (index, file) => {
    const maxSizeInBytes = 1024 * 1024; // 1MB

    if (file && file.size > maxSizeInBytes) {
      // The file size exceeds the limit, so you can display an error message or take some action.
      alert(
        "File size exceeds the maximum allowed size (1MB). Please choose a smaller file."
      );
      // Set the value of the file input to an empty string if no file is selected
      const fileInput = document.getElementsByName(`pic${index + 1}`)[0];
      fileInput.value = "";
    } else {
      const updatedMembers = [...members];
      updatedMembers[index].pic = file;
      setmembers(updatedMembers);
    }
  };

  const handleFileChange = (setState, event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      // Check if file size is less than 2MB
      if (selectedFile.size <= 1 * 1024 * 1024) {
        setState(selectedFile);
      } else {
        // File size exceeds 2MB, reset file input
        event.target.value = null;
        setState(null);
        // You can also provide feedback to the user, e.g., show an error message
        alert("File size must be less than 1MB.");
      }
    }
  };

  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <div>
      {/* <SecurePageSection accessableUsers={"admin,eminenceChair"} /> */}
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence Finale Registration</h2>
            </Col>
          </Row>

          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                Points to be noted before filling up the form: last date for
                filling up the entry form is 9.00 pm on 10-01-2025.
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p>
                1. Keep name, Aadhaar card, mobile number, email id, latest
                picture and payment receipt of each person handy to upload.
              </p>
              <p>2. ⁠in case of multiple forms all will be rejected. </p>
              <p>3. ⁠No refunds under any circumstances. </p>
              <p>
                4. Limited rooms are available and in case of non availability
                Team Eminence reserves the right to refuse accommodation. Early
                check in is not guaranteed and subject to availability and on
                first come first served basis.
              </p>
              <p>
                5. Team Eminence reserves the right to refuse the registration
                and/or entry of person(s) in to the venue even if payment is
                made and/or confirmation email is received.
              </p>
              <p>
                6. Please assure that all document(s) and/or picture(s) being
                uploaded are sharp and clearly visible.
              </p>
              <p>
                7. Upon entry to the venue please present a copy of the
                confirmation email to the registration desk and collect your
                food coupon booklet and keys to the room.
              </p>
              <p>
                8. ⁠In case check out is not completed by returning the keys to
                the registration desk by 9.30 am on Sunday the 2nd day of
                February 2025, applicable one day rent applicable on the said
                day and date will be collected.{" "}
              </p>
              <p>
                9. ⁠Please do not call any Team Eminence members. All
                correspondence by WhatsApp ONLY. Do not argue at the
                registration desk upon check in for room(s) allotment as the
                hotel management is the deciding authority and Team Eminence is
                not a part of the process.
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button className="btn--secondary" onClick={handleClose}>
                Agree
              </Button>
            </Modal.Footer>
          </Modal>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  maxlength="7000"
                  onChange={(e) => setRegName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  value={grpName}
                  maxlength="7000"
                  onChange={(e) => setGrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {groups.map((group, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() +
                        group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
          </Row>

          {members.map((event, index) => (
            <div key={index}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="formSectionHeader"
                    style={{
                      marginTop: "30px",
                      width: "97%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ marginLeft: "30px" }}>
                      {`Member ${index + 1} Details`}
                    </h4>
                    {index > 0 && (
                      <Button
                        className="btn--secondary"
                        onClick={() => removeMember(index)}
                      >
                        X
                      </Button>
                    )}
                  </div>
                </div>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Name ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`name${index + 1}`}
                      value={event.name}
                      maxlength="7000"
                      onChange={(e) => handlenameChange(index, e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Aadhar ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`aadhar${index + 1}`}
                      value={event.aadhar}
                      maxlength="7000"
                      onChange={(e) =>
                        handleaadharChange(index, e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Mobile ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`mobile${index + 1}`}
                      value={event.mobile}
                      maxlength="7000"
                      onChange={(e) =>
                        handlemobileChange(index, e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Email ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`email${index + 1}`}
                      value={event.email}
                      maxlength="7000"
                      onChange={(e) => handleemailChange(index, e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={4} xs={12}>
                  <Form.Group>
                    <Form.Label>
                      {`Recent Photo ${index + 1}`}
                      <span style={{ fontSize: "14px" }}>
                        (Maximum file size: 1.5mb, Format: jpg only)
                      </span>
                    </Form.Label>
                    <Form.Control
                      name={`pic${index + 1}`}
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) =>
                        handlepicChange(index, e.target.files[0])
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ))}

          <Row style={{ marginTop: "10px" }}>
            <Col>
              {members.length < 3 ? (
                <Button className="btn--secondary" onClick={addEvent}>
                  Add Member
                </Button>
              ) : (
                <>
                  <Button
                    className="btn--secondary"
                    disabled
                    onClick={addEvent}
                  >
                    Maximimun 3 members per room
                  </Button>
                  <p>Fill another form</p>
                </>
              )}
            </Col>
          </Row>
          <Row>
            <Col md={9}>
              <Row>
                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Number of People in room</Form.Label>
                    <Form.Control
                      name="numperRoom"
                      value={numperRoom}
                    ></Form.Control>
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Amount</Form.Label>
                    <Form.Control
                      name="amt"
                      value={amt}
                      maxlength="7000"
                      // onChange={(e) => setamt(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Date of Payment</Form.Label>
                    <Form.Control
                      name="payDate"
                      value={payDate}
                      maxlength="7000"
                      onChange={(e) => setpayDate(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>Payment UTR</Form.Label>
                    <Form.Control
                      name="payUTR"
                      value={payUTR}
                      maxlength="7000"
                      onChange={(e) => setpayUTR(e.target.value)}
                    />
                  </Form.Group>
                </Col>

                <Col md={12} xs={12}>
                  <Form.Group>
                    <Form.Label>Payment Screenshot</Form.Label>
                    <Form.Control
                      name="payment"
                      type="file"
                      accept="image/*"
                      onChange={(e) => handleFileChange(setpayment, e)}
                    />
                  </Form.Group>
                  {submitting ? (
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          Submitting Please Wait
                        </Tooltip>
                      }
                    >
                      <Button
                        style={{ marginTop: "30px" }}
                        className="btn--outline--dark"
                        disabled
                      >
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        Submiting
                      </Button>
                    </OverlayTrigger>
                  ) : (
                    <Button
                      style={{ marginTop: "30px" }}
                      className="btn--outline--dark"
                      onClick={(e) => FormSubmit(e)}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                      Submit
                    </Button>
                  )}
                </Col>
              </Row>
            </Col>
            <Col md={3}>
              <Image src={JSGIF_Qr} alt="jsgif-qr" fluid />
            </Col>
          </Row>
        </Form>

        <Modal centered show={showFormModal}>
          <Modal.Header>
            <Modal.Title>Form Submitted</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p>
              You have successfully Registered. Your application ID is :
              {submittedData?.id}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn--four" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default RegisterFinale;
