import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../components/Button.css";
import {
  Row,
  Col,
  Card,
  Form,
  Button,
  Container,
  Table,
} from "react-bootstrap";
import "../formsList.css";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import SecurePageSection from "../../SecurePageSection";
import { LinkContainer } from "react-router-bootstrap";

const RegisterFinalelist = () => {
  const user = useSelector((state) => state.authentication.user);

  const [forms, setForms] = useState([]);

  const fetchForms = async () => {
    const response = await axios.get(
      `/api/forms/ventures/eminence-finale-registrations/`
    );
    console.log(response.data);
    setForms(response.data);
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchForms();
  }, []);

  return (
    <div>
      <SecurePageSection accessableUsers={"admin,eminenceChair"} />
      <Container className="body">
        <h3>Finale Full Booking</h3>
        <Table responsive="md" striped bordered>
          <thead className="tableHead">
            <tr>
              <th>S. No.</th>
              <th>Group Name</th>
              <th>Region Name</th>
              <th>Name 1</th>
              <th>Name 2</th>
              <th>Name 3</th>
              {(() => {
                if (
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "admin") ||
                  (typeof user !== "undefined" &&
                    typeof user.username !== "undefined" &&
                    user.accessGroup === "eminenceChair")
                ) {
                  return <th>Form</th>;
                }
              })()}
            </tr>
          </thead>

          <tbody className="tableBody">
            {forms.slice(0).map((form, index) => (
              <tr
                key={index}
                style={{ textTransform: "capitalize", textAlign: "center" }}
              >
                <td>{index + 1}</td>
                <td>{form.grpName}</td>
                <td>{form.regName}</td>
                <td>{form.name1}</td>
                <td>{form.name2}</td>
                <td>{form.name3}</td>
                <td style={{ width: "20rem" }}>
                  <Row>
                    <Col>
                      <div style={{ textTransform: "center" }}>
                        <LinkContainer
                          to={`/eminence/finale-booking/response/${form.id}`}
                        >
                          <Button variant="light" className="btn--four">
                            View Form
                          </Button>
                        </LinkContainer>
                      </div>
                    </Col>
                  </Row>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default RegisterFinalelist;
