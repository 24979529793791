import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const RegQ6Form = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setregName] = useState("");
  const [fNum, setfNum] = useState("1");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [regChairName, setregChairName] = useState("");
  const [convNum, setconvNum] = useState("");
  const [timings, settimings] = useState("");
  const [socialMedia, setsocialMedia] = useState("");
  const [collaborationGroup, setcollaborationGroup] = useState("");
  const [eminenceForms, seteminenceForms] = useState("");
  const [aashray, setaashray] = useState("");
  const [runbhoomi, setrunbhoomi] = useState("");

  const [regEmails, setRegEmails] = useState("");
  const [grpEmails, setGrpEmails] = useState("");

  // const [regConvenor, setRegConvenor] = useState("");

  const navigate = useNavigate();

  const fetchRegEmails = async () => {
    const chairmanResponse = await axios.get(
      `api/data/${regName}/region-ob/emails/`
    );
    const chairmanEmail = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.email);
    setRegEmails(chairmanEmail);

    const convenorResponse = await axios.get(
      `api/data/eminence/region-convenor/emails/`
    );
    const convenorEmail = convenorResponse.data
      .filter(
        (item) =>
          item.post ===
            `${
              regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()
            } Region Convenor` ||
          item.post ===
            `${
              regName.charAt(0).toUpperCase() + regName.slice(1).toLowerCase()
            } Region Coordinator`
      )
      .map((item) => item.email);
    const chairmanName = chairmanResponse.data
      .filter((item) => item.post === "chairman")
      .map((item) => item.name);

    // setRegConvenor(convenorEmail);
    setregChairName(chairmanName);
  };

  useEffect(() => {
    fetchRegEmails();
  }, [regName]);

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email +
      "," +
      regEmails;
    // "," +
    // regConvenor;

    console.log(finalEmail);

    if (!regName) {
      alert("Please Submit your Region Name");
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("fNum", fNum);
    formField.append("name", name);
    formField.append("email", email);
    formField.append("regChairName", regChairName);
    formField.append("convNum", convNum);
    formField.append("timings", timings);
    formField.append("socialMedia", socialMedia);
    formField.append("collaborationGroup", collaborationGroup);
    formField.append("eminenceForms", eminenceForms);
    formField.append("aashray", aashray);
    formField.append("runbhoomi", runbhoomi);

    events.forEach((event, index) => {
      formField.append(`subEvent${index + 1}`, event.subEvent);
      if (event.picEvent !== null) {
        formField.append(`picEvent${index + 1}`, event.picEvent);
      }
    });

    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-q6-regions/",
      data: formField,
    })
      .then(function (response) {
        console.log(response);
        navigate(`/eminence/q6/region/response/${response.data.id}`, {
          replace: true,
        });
      })
      .catch(function (error) {
        console.log(error);
        alert(
          "We encountered an error processing your request. Please try again later"
        );
      });

    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const initialEvents = [
    {
      subEvent: "",
      picEvent: null,
    },
  ];

  const [events, setEvents] = useState(initialEvents);

  const addEvent = () => {
    if (events.length < 10) {
      setEvents([...events, { subEvent: "", picEvent: null }]);
    }
  };

  const removeEvent = (index) => {
    const updatedEvents = [...events];
    updatedEvents.splice(index, 1);
    setEvents(updatedEvents);
  };

  const handleSubEventChange = (index, value) => {
    const updatedEvents = [...events];
    updatedEvents[index].subEvent = value;
    setEvents(updatedEvents);
  };

  const handlePicEventChange = (index, file) => {
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB

    if (file && file.size > maxSizeInBytes) {
      // The file size exceeds the limit, so you can display an error message or take some action.
      alert(
        "File size exceeds the maximum allowed size (2MB). Please choose a smaller file."
      );
      // Set the value of the file input to an empty string if no file is selected
      const fileInput = document.getElementsByName(`picEvent${index + 1}`)[0];
      fileInput.value = "";
    } else {
      const updatedEvents = [...events];
      updatedEvents[index].picEvent = file;
      setEvents(updatedEvents);
    }
  };

  return (
    <div>
      <SecurePageSection
        accessableUsers={
          "admin,region,former,intDirector,offBearer,eminence,eminenceChair"
        }
      />
      {/* <SecurePageSection accessableUsers={"admin,eminenceChair"} /> */}
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence 6th Quarter Application (Region)</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  maxlength="500"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  maxlength="500"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  maxlength="500"
                  onChange={(e) => setregName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Number of eminence forms filled for the current quarter
                </Form.Label>
                <Form.Control
                  name="fNum"
                  value={fNum}
                  rows="3"
                  placeholder="To be filled if you have more than 20 events and filling a 2nd/3rd form. Only write the number Ex: 2 or 3"
                  maxlength="500"
                  onChange={(e) => setfNum(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Chairman Name</Form.Label>
                <Form.Control
                  style={{ textTransform: "capitalize" }}
                  name="regChairName"
                  value={regChairName}
                  placeholder="Region Chairman's Full name"
                  maxlength="500"
                  onChange={(e) => setregChairName(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Convenor Contact Number</Form.Label>
                <Form.Control
                  name="convNum"
                  value={convNum}
                  rows="3"
                  placeholder="Region Eminence Contact Number"
                  maxlength="500"
                  onChange={(e) => setconvNum(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Timings</Form.Label>
                <Form.Control
                  as="textarea"
                  name="timings"
                  value={timings}
                  rows="3"
                  placeholder="Timings : Time given & No. of Meetings with different theme."
                  maxlength="500"
                  onChange={(e) => settimings(e.target.value)}
                />
              </Form.Group>
            </Col>
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No of Reports in Mangalyatra till 5th Quarter Your presence of
                  Social Media of JSGIF precisely on JSGIF's FB / Youtube &
                  Instagram Page.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="socialMedia"
                  value={socialMedia}
                  rows="3"
                  placeholder="socialMedia"
                  maxlength="500"
                  onChange={(e) => setsocialMedia(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  Attendance of Region office bearer / JSGIF office bearer in
                  your group events & your group attendance in Region Meeting /
                  JSGIF Meeting. / any Special meeting with collaboration of
                  more then 2 groups / Youth / Sangini or other region groups
                  that has benefited society on least financial cost
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="collaborationGroup"
                  value={collaborationGroup}
                  rows="3"
                  placeholder=""
                  maxlength="500"
                  onChange={(e) => setcollaborationGroup(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  No of Eminence form filled out in last 5th Quarter.
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="eminenceForms"
                  value={eminenceForms}
                  rows="3"
                  placeholder="Eminence form upto 5th Quaters, If yes how many Quaters."
                  maxlength="500"
                  onChange={(e) => seteminenceForms(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  Your Presence / Participation in JSGIF Ashray Committee /
                  Events
                </Form.Label>
                <Form.Control
                  name="aashray"
                  value={aashray}
                  rows="3"
                  placeholder=" Your Presence / Participation in JSGIF Ashray Committee /
                  Events"
                  maxlength="500"
                  onChange={(e) => setaashray(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>
                  Your Participation in JSGIF Runbhoomi Sangini
                </Form.Label>
                <Form.Control
                  name="runbhoomi"
                  value={runbhoomi}
                  rows="3"
                  placeholder="Your Attendance / participation in Dream runbhoomi of Amish Bhai i.e JSGIF Udaan, Pari, Anmol ..... All committees"
                  maxlength="500"
                  onChange={(e) => setrunbhoomi(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {events.map((event, index) => (
            <div key={index}>
              <Row>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <div
                    className="formSectionHeader"
                    style={{
                      marginTop: "30px",
                      width: "97%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <h4 style={{ marginLeft: "30px" }}>
                      {`Event ${index + 1}`}{" "}
                    </h4>
                    {index > 0 && (
                      <Button
                        className="btn--secondary"
                        onClick={() => removeEvent(index)}
                      >
                        X
                      </Button>
                    )}
                  </div>
                </div>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>{`Event Subject ${index + 1}`}</Form.Label>
                    <Form.Control
                      name={`subEvent${index + 1}`}
                      value={event.subEvent}
                      placeholder={`Subject of Event ${index + 1}`}
                      maxlength="500"
                      onChange={(e) =>
                        handleSubEventChange(index, e.target.value)
                      }
                    />
                  </Form.Group>
                </Col>

                <Col md={6} xs={12}>
                  <Form.Group>
                    <Form.Label>
                      {`Event Photo ${index + 1}`}
                      <span style={{ fontSize: "14px" }}>
                        (Maximum file size: 1.5mb, Format: jpg only)
                      </span>
                    </Form.Label>
                    <Form.Control
                      name={`picEvent${index + 1}`}
                      type="file"
                      accept="image/jpg, image/jpeg"
                      onChange={(e) =>
                        handlePicEventChange(index, e.target.files[0])
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </div>
          ))}

          <Row style={{ marginTop: "10px" }}>
            <Col>
              {events.length < 10 ? (
                <Button className="btn--secondary" onClick={addEvent}>
                  Add Event
                </Button>
              ) : (
                <>
                  <Button
                    className="btn--secondary"
                    disabled
                    onClick={addEvent}
                  >
                    Maximimun 10 Events
                  </Button>
                  <p>Fill another form for more events</p>
                </>
              )}
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>
              }
            >
              <Button
                style={{ marginTop: "30px" }}
                className="btn--outline--dark"
                disabled
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
      </Container>
    </div>
  );
};

export default RegQ6Form;
