import React, { useState, useRef, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Image,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import logo from "../images/Eminence.jpg";
import "./ventures.css";
import { useNavigate } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import axios from "axios";
import { saveAs } from "file-saver";
import pdfFileGuidlines from "../images/Eminence Guidelines.pdf";
import pdfFileQ1 from "../images/Eminence Q1.pdf";
import pdfFileAnnual from "../images/Eminence Annual.pdf";
import { useSelector } from "react-redux";

function Eminence() {
  const user = useSelector((state) => state.authentication.user);

  const navigate = useNavigate();

  const zoomBgRedirect = () => {
    navigate(`/zoom-background/eminence`);
  };

  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState("");

  const [bookWidth, setBookWidth] = useState("");
  const [bookHeight, setBookHeight] = useState("");

  const [isPageFlipReady, setIsPageFlipReady] = useState(false);

  const pageFlipRef = useRef();

  const handlePageChange = () => {
    let page = pageFlipRef.current.pageFlip().getCurrentPageIndex();
    setCurrentPage(page);
    console.log(page);
  };

  useEffect(() => {
    if (isPageFlipReady) {
      handlePageChange();
    }
  }, [isPageFlipReady]);

  const prevPage = () => {
    pageFlipRef.current.pageFlip().flipPrev();
  };

  const nextPage = () => {
    pageFlipRef.current.pageFlip().flipNext();
  };

  useEffect(() => {
    const totalPageCounter = () => {
      let counter = pageFlipRef.current.pageFlip().getPageCount();
      setTotalPages(counter);
    };

    if (isPageFlipReady) {
      totalPageCounter();
    }
  }, [isPageFlipReady]);

  const fetchPages = async () => {
    const pageResponse = await axios.get(`/api/book/14`);
    console.log(pageResponse.data);
    setPages(pageResponse.data);
  };

  useEffect(() => {
    fetchPages();
  }, []);

  const downloadPDFGuidelines = () => {
    saveAs(pdfFileGuidlines, "Eminence Guidelines.pdf");
  };
  const downloadPDFQ1 = () => {
    saveAs(pdfFileQ1, "Eminence 1st Quarter Evaluating System .pdf");
  };

  const downloadPDFAnnual = () => {
    saveAs(pdfFileAnnual, "Eminence Annual Awards Category.pdf");
  };

  const [turnEnable, setTurnEnable] = useState(true);

  useEffect(() => {
    function handleResize() {
      const isMobile = window.innerWidth < 768; // or any other breakpoint you prefer
      setTurnEnable(!isMobile);
    }
    handleResize(); // Call handleResize initially to set the initial value of turnEnable
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  const divRef = useRef(null);

  const [isFullscreen, setIsFullscreen] = useState(false);

  const handleFullscreen = () => {
    const element = divRef.current;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const handleExitFullscreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  useEffect(() => {
    function handleContainer() {
      if (window.innerWidth > 1400) {
        setBookWidth(isFullscreen ? "1400px" : "1400px");
        setBookHeight(isFullscreen ? "1060px" : "100%");
      } else {
        setBookWidth("100%");
        setBookHeight("100%");
      }
    }
    handleContainer();
  }, [window.innerWidth, isFullscreen]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(
        document.fullscreenElement ||
          document.webkitFullscreenElement ||
          document.mozFullScreenElement ||
          document.msFullscreenElement
      );
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  const [committees, setCommittees] = useState([]);
  const [committeeChairman, setCommitteeChairman] = useState(null);

  const fetchCommittee = async () => {
    const response = await axios.get(`/api/data/venture-committee/eminence`);

    const sortedData = response.data.sort((a, b) => a.order - b.order);
    const special = sortedData.find((committee) => committee.order === "1");

    setCommitteeChairman(special);
    setCommittees(sortedData.filter((committee) => committee.order !== "1"));
  };

  useEffect(() => {
    fetchCommittee();
  }, []);

  return (
    <div>
      <div className="ventureContainer">
        <Row>
          <Col md={3} xs={6}>
            <Image src={logo} fluid className="logoImg" />
          </Col>
          <Col
            md={6}
            xs={6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <h1>EMINENCE</h1>
          </Col>
          <Col md={3} xs={6}></Col>
        </Row>
      </div>
      <div
        className="ventureContainer"
        style={{ marginTop: "10px", paddingTop: "10px" }}
      >
        <Row>
          <h2 style={{ textAlign: "center" }}>Committee Chairman</h2>
        </Row>
        <Row>
          {committeeChairman && (
            <div>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
                sm="12"
                md="12"
                lg="12"
              >
                <div className="flip2">
                  <Card className="front2">
                    <Row
                      style={{
                        borderTopLeftRadius: "20px",
                        borderTopRightRadius: "20px",
                      }}
                    >
                      <Card.Img
                        className="cardImage1"
                        src={committeeChairman.photo}
                      ></Card.Img>
                    </Row>
                    <Row>
                      <h2 className="userName">
                        JSGian {committeeChairman.name}
                      </h2>
                    </Row>
                  </Card>
                  <Card className="back2">
                    <h2 className="userName1" style={{ marginBottom: "10px" }}>
                      JSGian {committeeChairman.name}
                    </h2>
                    <h3
                      style={{ fontSize: "18px", margin: "0" }}
                      className="userName1"
                    >
                      {committeeChairman.post}
                    </h3>
                    <p style={{ fontSize: "14px" }} className="userInfoEmail">
                      <span className="infoHead">Email : </span>{" "}
                      {committeeChairman.email}
                    </p>
                    <p style={{ fontSize: "14px" }} className="userInfo1">
                      <span className="infoHead">Contact Number : </span>
                      {committeeChairman.mobile}
                    </p>
                  </Card>
                </div>
              </Col>
            </div>
          )}
          <h2 style={{ textAlign: "center" }}>Committee Members</h2>
          {committees.map((user) => (
            <Col
              style={{
                margin: "1em 0em",
                display: "flex",
                justifyContent: "center",
                flexFlow: "column",
              }}
              sm="6"
              md="3"
              lg="2"
              key={user.id}
            >
              <Row
                style={{
                  height: "2em",
                  padding: "0 20px",
                  marginBottom: "10px",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgba(55,3,122,1)",
                    color: "#face1d",
                    borderRadius: "20px",
                  }}
                >
                  <h4
                    style={{
                      fontSize: "18px",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                  >
                    {user.post.split(" ")[0]}
                  </h4>
                </div>
              </Row>
              <div className="flip3">
                <Card className="front3">
                  <Row
                    style={{
                      borderTopLeftRadius: "20px",
                      borderTopRightRadius: "20px",
                    }}
                  >
                    <Card.Img
                      className="cardImage2"
                      src={user.photo}
                    ></Card.Img>
                  </Row>
                  <Row>
                    <h2 className="userName" style={{ margin: "0" }}>
                      JSGian {user.name}
                    </h2>
                  </Row>
                </Card>
                <Card className="back3">
                  <h2
                    className="userName1"
                    style={{ fontSize: "18px", marginBottom: "0px" }}
                  >
                    JSGian {user.name}
                  </h2>
                  <h3
                    style={{ fontSize: "14px", margin: "0" }}
                    className="userName1"
                  >
                    {user.post}
                  </h3>
                  <p
                    style={{ fontSize: "12px", margin: "0", marginTop: "5px" }}
                    className="userInfoEmail"
                  >
                    <span className="infoHead">Email : </span> {user.email}
                  </p>
                  <p
                    style={{ fontSize: "12px", margin: "0" }}
                    className="userInfo1"
                  >
                    <span className="infoHead">Contact Number : </span>
                    {user.mobile}
                  </p>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
      <div
        className="ventureContainer"
        style={{ marginTop: "10px", paddingTop: "10px" }}
      >
        <Row>
          <Col md={4} xs={12} style={{ marginTop: "20px" }}>
            <Row
              style={{
                marginTop: "20px",
                textAlign: "center",
                padding: "0px 15px",
              }}
            >
              <Button onClick={zoomBgRedirect} className="btn--four">
                Get Zoom Background
              </Button>
            </Row>
            {/* <Row
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button
                style={{ width: "100%" }}
                className="btn--secondary"
                onClick={() => downloadPDFGuidelines()}
              >
                Download Guidelines
              </Button>
            </Row>
            <Row
              style={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button
                style={{ width: "100%" }}
                className="btn--secondary"
                onClick={() => downloadPDFQ1()}
              >
                Download 2nd Quarter Evaluating System
              </Button>
            </Row>
            <Row
              style={{
                marginTop: "6px",
                display: "flex",
                justifyContent: "center",
                padding: "0px 15px",
              }}
            >
              <Button
                style={{ width: "100%" }}
                className="btn--secondary"
                onClick={() => downloadPDFAnnual()}
              >
                Download Annual Awards Category
              </Button>
            </Row> */}
            <h2
              style={{
                textAlign: "center",
                marginTop: "20px",
                marginBottom: "0",
              }}
            >
              Forms
            </h2>

            <>
              <Row
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  padding: "0px 15px",
                }}
              >
                <Button
                  onClick={() => navigate("/form/eminence/finale-registration")}
                  className="btn--three"
                >
                  Eminence Finale Full Booking
                </Button>
              </Row>
              <Row
                style={{
                  marginTop: "20px",
                  textAlign: "center",
                  padding: "0px 15px",
                }}
              >
                <Button
                  onClick={() =>
                    navigate("/form/eminence/finale-registration-2")
                  }
                  className="btn--three"
                >
                  Eminence Finale Food & Entry Booking
                </Button>
              </Row>
            </>

            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "group") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() => navigate("/form/eminence/group-finale")}
                        className="btn--four"
                      >
                        Eminence Group Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "group") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/form/eminence/group-president-finale")
                        }
                        className="btn--four"
                      >
                        Eminence Group President Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "group") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/form/eminence/group-sec-finale")
                        }
                        className="btn--four"
                      >
                        Eminence Group Secretary Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "region") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() => navigate("/form/eminence/region-finale")}
                        className="btn--four"
                      >
                        Eminence Region Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "region") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/form/eminence/region-chair-finale")
                        }
                        className="btn--four"
                      >
                        Eminence Region Chairman Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "region") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/form/eminence/region-sec-finale")
                        }
                        className="btn--four"
                      >
                        Eminence Region Secretary Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "sangini") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "sanginiGroup") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/form/eminence/sangini-president-finale")
                        }
                        className="btn--four"
                      >
                        Eminence Sangini President Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}
            {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "sangini") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "sanginiGroup") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/form/eminence/sangini-finale")
                        }
                        className="btn--four"
                      >
                        Eminence Sangini Finale
                      </Button>
                    </Row>
                  </>
                );
              }
            })()} */}

            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "region") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <OverlayTrigger
                      style={{ width: "100%" }}
                      overlay={
                        <Tooltip id="tooltip-disabled">
                          The applications for 6th quarter are closed
                        </Tooltip>
                      }
                    >
                      <Row
                        style={{
                          marginTop: "20px",
                          textAlign: "center",
                          padding: "0px 15px",
                        }}
                      >
                        <Button disabled className="btn--four">
                          Region 6th Quarter Application
                        </Button>
                      </Row>
                    </OverlayTrigger>

                    <Row
                      style={{
                        marginTop: "5px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/tutorial/eng/eminence-region")
                        }
                        className="btn--secondary"
                      >
                        Tutorial for Region 1st Quarter Application
                      </Button>
                    </Row>
                  </>
                );
              }
            })()}{" "} */}
            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "region") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <>
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        className="btn--four"
                        onClick={() => navigate("/form/eminence/q6/region")}
                      >
                        Region 6th Quarter Application
                      </Button>
                    </Row>

                    <Row
                      style={{
                        marginTop: "5px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        onClick={() =>
                          navigate("/tutorial/eng/eminence-region")
                        }
                        className="btn--secondary"
                      >
                        Tutorial for Region Application
                      </Button>
                    </Row>
                  </>
                );
              }
            })()} */}
            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "offBearer") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <OverlayTrigger
                    style={{ width: "100%" }}
                    overlay={<Tooltip id="tooltip-disabled">Will be available soon</Tooltip>}
                  >
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        disabled
                        onClick={() => navigate("/form/eminence/h1/ob")}
                        className="btn--four"
                      >
                        OB 1st Half Year Application
                      </Button>
                    </Row>
                  </OverlayTrigger>
                );
              }
            })()} */}
            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "offBearer") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <Row
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                      padding: "0px 15px",
                    }}
                  >
                    <Button onClick={() => navigate("/form/eminence/h1/ob")} className="btn--four">
                      OB 1st Half Year Application
                    </Button>
                  </Row>
                );
              }
            })()} */}
            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <OverlayTrigger
                    overlay={<Tooltip id="tooltip-disabled">Will be available soon</Tooltip>}
                  >
                    <Row
                      style={{
                        marginTop: "20px",
                        textAlign: "center",
                        padding: "0px 15px",
                      }}
                    >
                      <Button
                        disabled
                        onClick={() => navigate("/form/eminence/h1/convenor")}
                        className="btn--four"
                      >
                        Eminence Convenor 1st Half Year Application
                      </Button>
                    </Row>
                  </OverlayTrigger>
                );
              }
            })()} */}
            {/* {(() => {
              if (
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminenceChair") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "eminence") ||
                (typeof user !== "undefined" &&
                  typeof user.username !== "undefined" &&
                  user.accessGroup === "admin")
              ) {
                return (
                  <Row
                    style={{
                      marginTop: "20px",
                      textAlign: "center",
                      padding: "0px 15px",
                    }}
                  >
                    <Button
                      onClick={() => navigate("/form/eminence/h1/convenor")}
                      className="btn--four"
                    >
                      Eminence Convenor 1st Half Year Application
                    </Button>
                  </Row>
                );
              }
            })()}*/}
          </Col>

          <Col md={8} xs={12} style={{ marginTop: "20px" }}>
            <Row
              ref={divRef}
              style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "#face1d",
              }}
            >
              <div
                className="gurukul-book-container"
                style={{ width: bookWidth, height: bookHeight }}
              >
                <Row style={{ display: "flex", justifyContent: "center" }}>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    {isFullscreen ? (
                      <Button
                        className="btn--secondary"
                        style={{
                          width: "160px",
                          margin: "0 20px",
                          padding: "8px",
                        }}
                        onClick={handleExitFullscreen}
                      >
                        Exit Fullscreen
                      </Button>
                    ) : (
                      <Button
                        className="btn--secondary"
                        style={{
                          width: "160px",
                          margin: "0 20px",
                          padding: "8px",
                        }}
                        onClick={handleFullscreen}
                      >
                        Fullscreen View
                      </Button>
                    )}
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      style={{ width: "160px", margin: "0 20px" }}
                      className="btn--primary"
                      onClick={prevPage}
                    >
                      Prev Page
                    </Button>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <p style={{ fontWeight: "600", fontSize: "20px" }}>
                      Page: {currentPage + 1}/ {totalPages}
                    </p>
                  </Col>
                  <Col
                    xs={12}
                    md={3}
                    style={{
                      marginTop: "5px",
                      justifyContent: "center",
                      textAlign: "center",
                    }}
                  >
                    <Button
                      style={{ width: "160px", margin: "0 20px" }}
                      className="btn--primary"
                      onClick={nextPage}
                    >
                      Next Page
                    </Button>
                  </Col>
                </Row>
                <div className="bookBook" style={{ marginTop: "20px" }}>
                  {pages.length !== [] ? (
                    <>
                      <HTMLFlipBook
                        style={{
                          margin: "0 auto",
                          touchAction: "manipulation",
                        }}
                        size="stretch"
                        width={508}
                        height={715}
                        ref={pageFlipRef}
                        showCover={true}
                        minWidth={315}
                        maxWidth={1000}
                        minHeight={400}
                        maxHeight={1533}
                        mobileScrollSupport={false}
                        useMouseEvents={turnEnable}
                        onFlip={handlePageChange}
                        onInit={() => setIsPageFlipReady(true)}
                      >
                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page11})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page9})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page10})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.coverPage})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page1})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page2})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page3})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page4})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page5})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page6})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page7})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.page8})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>

                        <div>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              backgroundImage: `url(${pages.lastPage})`,
                              backgroundSize: "cover",
                            }}
                          ></div>
                        </div>
                      </HTMLFlipBook>
                    </>
                  ) : (
                    <div>Loading...</div>
                  )}
                </div>
              </div>
            </Row>
          </Col>
        </Row>
      </div>
      {(() => {
        if (
          (typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.accessGroup === "eminenceChair") ||
          (typeof user !== "undefined" &&
            typeof user.username !== "undefined" &&
            user.accessGroup === "admin")
        ) {
          return (
            <div
              className="ventureContainer"
              style={{ marginTop: "10px", paddingTop: "10px" }}
            >
              <Row>
                <h2 style={{ textAlign: "center" }}>Responses</h2>
              </Row>
              <Row>
                <h3 style={{ textAlign: "center" }}>Eminence Finale Booking</h3>
              </Row>
              <Row>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/finale-booking")}
                      className="btn--four"
                    >
                      Eminence Finale Full Booking
                    </Button>
                  </Row>
                </Col>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/finale2-booking")}
                      className="btn--four"
                    >
                      Eminence Finale Entry & Food Booking
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row>
                <h3 style={{ textAlign: "center" }}>Region</h3>
              </Row>
              <Row>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/reg")}
                      className="btn--four"
                    >
                      Regions
                    </Button>
                  </Row>
                </Col>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/reg-chair")}
                      className="btn--four"
                    >
                      Region Chairman
                    </Button>
                  </Row>
                </Col>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/reg-sec")}
                      className="btn--four"
                    >
                      Region Secretary
                    </Button>
                  </Row>
                </Col>
              </Row>
              <Row>
                <h3 style={{ textAlign: "center" }}>Group</h3>
              </Row>
              <Row>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/grp")}
                      className="btn--four"
                    >
                      Groups
                    </Button>
                  </Row>
                </Col>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/grp-prez")}
                      className="btn--four"
                    >
                      Group President
                    </Button>
                  </Row>
                </Col>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/grp-sec")}
                      className="btn--four"
                    >
                      Group Secretary
                    </Button>
                  </Row>
                </Col>
              </Row>

              <Row>
                <h3 style={{ textAlign: "center" }}>Sangini</h3>
              </Row>
              <Row>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/sgn")}
                      className="btn--four"
                    >
                      Sangini
                    </Button>
                  </Row>
                </Col>
                <Col style={{ margin: "0 20px" }}>
                  <Row>
                    <Button
                      onClick={() => navigate("/list/eminence/sgn-prez")}
                      className="btn--four"
                    >
                      Sangini President
                    </Button>
                  </Row>
                </Col>
              </Row>
            </div>
          );
        }
      })()}
    </div>
  );
}

export default Eminence;
