import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  Container,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import SecurePageSection from "../../../SecurePageSection";

const SgnFinals = () => {
  const user = useSelector((state) => state.authentication.user);
  const [submitting, setSubmitting] = useState(false);

  const [regName, setregName] = useState("");
  const [grpName, setgrpName] = useState("");
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [meet, setmeet] = useState("");
  const [programme, setprogramme] = useState("");
  const [runbhoomi, setrunbhoomi] = useState("");
  const [netri, setnetri] = useState("");
  const [maitree, setmaitree] = useState("");
  const [participation, setparticipation] = useState("");
  const [mangalyatra, setmangalyatra] = useState("");
  const [newPaper, setnewPaper] = useState("");

  const navigate = useNavigate();

  const [showFormModal, setShowFormModal] = useState(false);
  const [submittedData, setsubmittedData] = useState("");
  const handleModalClose = () => {
    navigate("/");
    setShowFormModal(false);
  };

  async function FormSubmit(event) {
    event.preventDefault();
    setSubmitting(true);
    let formField = new FormData();

    const finalEmail =
      "president@jsgif.co.in,secretarygeneral@jsgif.co.in,office@jsgif.co.in,dj22jsgif@gmail.com,dsmehta8842@gmail.com," +
      email;
    console.log(finalEmail);

    if (!regName || !grpName) {
      alert("Please Submit your Region Name, Group Name");
      setSubmitting(false);
      return;
    }

    formField.append("user", user?.user_id);
    formField.append("regName", regName);
    formField.append("grpName", grpName);
    formField.append("name", name);
    formField.append("email", email);
    formField.append("meet", meet);
    formField.append("programme", programme);
    formField.append("runbhoomi", runbhoomi);
    formField.append("netri", netri);
    formField.append("maitree", maitree);
    formField.append("participation", participation);
    formField.append("mangalyatra", mangalyatra);
    formField.append("newPaper", newPaper);

    formField.append("emailList", finalEmail);

    await axios({
      method: "post",
      url: "/api/forms/ventures/eminence-sgn-finale-registrations/",
      data: formField,
    })
      .then(function(response) {
        console.log(response);
        setsubmittedData(response.data);
        setShowFormModal(true);
      })
      .catch(function(error) {
        console.log(error);
        alert(
          "We encountered an error processing your request. Please try again later"
        );
      });

    setSubmitting(false);
  }

  const [regions, setRegions] = useState([]);
  const fetchRegions = async () => {
    const response = await axios.get(`/user/region/`);
    setRegions(response.data);
  };

  useEffect(() => {
    fetchRegions();
  }, []);

  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    const response = await axios.get(`/user/${regName}/groups/`);
    setGroups(response.data);
  };
  useEffect(() => {
    fetchGroups();
  }, [regName]);

  const [sgnGroups, setSgnGroups] = useState([]);
  const fetchSgnGroups = async () => {
    const response = await axios.get(`/user/${regName}/sangini/`);
    setSgnGroups(response.data);
  };
  useEffect(() => {
    fetchSgnGroups();
  }, [regName]);

  return (
    <div>
      {/* <SecurePageSection
        accessableUsers={
          "admin,region,group,former,intDirector,offBearer,eminence,eminenceChair,sangini,sanginiGroup"
        }
      /> */}
      <SecurePageSection accessableUsers={"admin"} />
      <Container>
        <Form>
          <Row>
            <Col lg={8}>
              <h2>Eminence Finals - Sangini</h2>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Name</Form.Label>
                <Form.Control
                  name="name"
                  value={name}
                  placeholder="Respondant's Full name"
                  maxlength="7000"
                  onChange={(e) => setname(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Your Email</Form.Label>
                <Form.Control
                  name="email"
                  value={email}
                  placeholder="Respondant's Email ID"
                  maxlength="7000"
                  onChange={(e) => setemail(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mb-3">
            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Region Name</Form.Label>
                <Form.Select
                  name="regName"
                  value={regName}
                  maxlength="7000"
                  onChange={(e) => setregName(e.target.value)}
                >
                  <option>Select your Region</option>
                  {regions.map((region, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={region.region.toLowerCase()}
                    >
                      {region.region.charAt(0).toUpperCase() +
                        region.region.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={6} xs={12}>
              <Form.Group>
                <Form.Label>Sangini Group Name</Form.Label>
                <Form.Select
                  name="grpName"
                  value={grpName}
                  onChange={(e) => setgrpName(e.target.value)}
                >
                  <option>Select your Group</option>
                  {sgnGroups.map((group, index) => (
                    <option
                      key={index}
                      style={{ textTransform: "capitalize" }}
                      value={group.group.toLowerCase()}
                    >
                      {group.group.charAt(0).toUpperCase() +
                        group.group.slice(1).toLowerCase()}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>Number of Meetings</Form.Label>
                <Form.Control
                  as="textarea"
                  name="meet"
                  value={meet}
                  rows="3"
                  placeholder="Number of Meetings"
                  maxlength="7000"
                  onChange={(e) => setmeet(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  ⁠Signature Programme done annually related to Manav seva,
                  health or women empowerment 10
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="programme"
                  value={programme}
                  rows="3"
                  placeholder="⁠Signature Programme done annually related to Manav seva, health or women empowerment 10"
                  maxlength="7000"
                  onChange={(e) => setprogramme(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  3. ⁠Participation in Sangini Runbhoomi 10
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="runbhoomi"
                  value={runbhoomi}
                  rows="3"
                  placeholder="3. ⁠Participation in Sangini Runbhoomi 10"
                  maxlength="7000"
                  onChange={(e) => setrunbhoomi(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  4. ⁠Participation in Sangini Netri Sabhagam 10
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="netri"
                  value={netri}
                  rows="3"
                  placeholder="4. ⁠Participation in Sangini Netri Sabhagam 10"
                  maxlength="7000"
                  onChange={(e) => setnetri(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  ⁠Participation in Sangini Maitree Programme 10
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="maitree
"
                  value={maitree}
                  rows="3"
                  placeholder="⁠Participation in Sangini Maitree Programme 10"
                  maxlength="7000"
                  onChange={(e) => setmaitree(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>
                  ⁠Participation in Region & Federation Programme like Gurukul,
                  Ahaar, Vidya, Ashray Committee 10
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="participation"
                  value={participation}
                  rows="3"
                  placeholder="⁠Participation in Region & Federation Programme like Gurukul, Ahaar, Vidya, Ashray Committee 10 "
                  maxlength="7000"
                  onChange={(e) => setparticipation(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>⁠Mangalyatra coverage 5</Form.Label>
                <Form.Control
                  as="textarea"
                  name="mangalyatra"
                  value={mangalyatra}
                  rows="3"
                  placeholder="⁠Mangalyatra coverage 5"
                  maxlength="7000"
                  onChange={(e) => setmangalyatra(e.target.value)}
                />
              </Form.Group>
            </Col>

            <Col lg={12} xs={12}>
              <Form.Group>
                <Form.Label>⁠New’s paper coverages 5</Form.Label>
                <Form.Control
                  as="textarea"
                  name="newPaper"
                  value={newPaper}
                  rows="3"
                  placeholder="⁠New’s paper coverages 5"
                  maxlength="7000"
                  onChange={(e) => setnewPaper(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>

          {submitting ? (
            <OverlayTrigger
              overlay={
                <Tooltip id="tooltip-disabled">Submitting Please Wait</Tooltip>
              }
            >
              <Button
                style={{ marginTop: "30px" }}
                className="btn--outline--dark"
                disabled
              >
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                Submiting
              </Button>
            </OverlayTrigger>
          ) : (
            <Button
              style={{ marginTop: "30px" }}
              className="btn--outline--dark"
              onClick={(e) => FormSubmit(e)}
            >
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              Submit
            </Button>
          )}
        </Form>
        <Modal centered show={showFormModal}>
          <Modal.Header>
            <Modal.Title>Form Submitted</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ display: "flex", justifyContent: "center" }}>
            <p>
              You have successfully Registered. Your application ID is :
              {submittedData?.id}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn--four" onClick={handleModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
};

export default SgnFinals;
