import React, { useEffect, useRef, useState } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import Images from "../../../images/index";
import { useLocation } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { useSelector } from "react-redux";
import "../../Response.css";

function RegChairFinalsResponse() {
  const user = useSelector((state) => state.authentication.user);

  const [response, setResponses] = useState([]);
  const responseId = useParams();

  const getResponse = async () => {
    const res = await axios.get(
      `/api/forms/ventures/eminence-regchair-finale-registration/${responseId.id}`
    );
    console.log(res.data);
    setResponses(res.data);
  };

  useEffect(() => {
    getResponse();
  }, []);

  const printDocument = () => {
    var divContents = document.getElementById("mainForm").innerHTML;
    var a = window.open("", "blank", "height=1123,width=800,scrollbars=yes,");
    a.document.write("<html>");
    a.document.write("<link>");
    a.document
      .write(`<link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/css/bootstrap.min.css" integrity="sha384-rbsA2VBKQhggwzxH7pPCaAqO46MgnOM80zW1RWuH61DGLwZJEdK2Kadq2F9CUG65" crossorigin="anonymous"
    />`);
    a.document.write("</link>");

    a.document.write(`
    <title>Eminence Group Q5</title>
    `);
    a.document.write("<style>");
    a.document.write(`@media print {
      @page {
        margin-top: 20px;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
      }
    }

    html {
      overflow-y: auto;
    }

    .fResponseBody {
      padding: 20px 20px;
      width: 800px;
      min-height: 1123px;
      background: #fff;
    }

    .page{
      page-break-after: always;
    }

    .centerFormat {
      width: 800px;
      margin: 0 auto;
    }

    .section2, .section3{
      margin-top: 30px;
    }

    .section4{
      margin-top: 20px;
    }

    .bordered-col{
      padding: 3px 10px;
      border: 1px solid #000;
    }

    .bordered-mid-col{
      padding: 3px 10px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row{
      padding: 3px 0;
      border: 1px solid #000;
    }

    .bordered-mid-row{
      padding: 2px 0px;
      border-left: 1px solid #000;
      border-right: 1px solid #000;
    }

    .bordered-row-b{
      padding: 2px 0;
      border: 1px solid #000;
      height: 30px;
    }

    .bordered-col-b{
      padding: 2px 5px;
      border: 1px solid #000;
      height: 30px;
    }

    .blueBG {
      background: rgb(185, 213, 250);
      border-radius: 8px;
      height: 20px;
      margin-bottom: 5px;
    }

    .bannerText {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .bannerText h1 {
      font-family: "Arial-Bold";
      font-size: 24px;
      font-weight: 600;
    }

    .bannerText h2 {
      font-family: "Arial-Bold";
      font-size: 20px;
      font-weight: 400;
    }

    .toptext {
      margin: 20px 0;
    }

    .logoImage {
      height: 80px;
      margin: 10px;
    }

    .bannerYellow {
      background: #face1d;
      border-top: 12px solid #3e4095;
      margin-bottom: 30px;
      -webkit-print-color-adjust: exact;
    }

    .Tbox {
      border: 1px solid rgb(0, 0, 0);
      padding: 11px 15px;
      margin: 20px 0;
    }

    .Tsingle-enter {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsingle-enter-label {
      font-family: "Arial";
      font-size: 12px;
      margin-top: 3px;
      margin-bottom: 0;
      line-height: 97%;
    }

    .Tdouble-enter {
      font-family: "Arial";
      margin-top: 11px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Tsign-enter {
      font-family: "Arial";
      margin-top: 30px;
      font-size: 12px;
      margin-bottom: 0;
    }

    .Hdouble-enter {
      font-family: "Arial";
      margin: 16px auto;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H2double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 16px;
      font-size: 16px;
      color: #000;
      font-weight: 600;
    }

    .H3double-enter {
      font-family: "Arial";
      margin: 0;
      margin-top: 14px;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H3single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 14px;
      color: #000;
      font-weight: 600;
    }

    .H4single-enter {
      font-family: "Arial";
      margin: 0;
      font-size: 12px;
      color: #000;
      font-weight: 600;
    }

    .Lsingle-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
    }

    .Ldouble-enter li {
      font-family: "Arial";
      font-size: 12px;
      margin-bottom: 0;
      margin-top: 12px
    }

    .response {
      font-family: "Arial";
      font-size: 12px;
      font-weight: 600;
      text-decoration: underline;
    }

    .Tsingle {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 8px;
    }

    .Tdouble {
      font-family: "Arial";
      font-size: 16px;
      text-decoration: none;
      font-weight: 400;
      color: rgb(0, 0, 0);
      margin-bottom: 24px;
    }`);
    a.document.write("</style>");
    a.document.write("<body>");
    a.document.write(divContents);
    a.document.write("</body></html>");
    a.document.close();
    setTimeout(function() {
      a.print();
    }, 1000);
  };

  const mainFormRef = useRef(null);

  return (
    <div>
      <Row style={{ marginBottom: "1em", marginTop: "1em" }}>
        <Col
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Button
            onClick={() => printDocument()}
            style={{ margin: "0 5px", marginBottom: "20px" }}
          >
            Download Form
          </Button>

          <h2>Eminence Region Chairman Finals</h2>
        </Col>
      </Row>

      <div id="mainForm" ref={mainFormRef} className="centerFormat">
        <div className="fResponseBody">
          <div className="page">
            <div className="bannerYellow">
              <Row className="T-Banner">
                <Col xs={1}>
                  <img
                    src={Images.LogoB}
                    className="logoImage"
                    alt="jsgBanner"
                  ></img>
                </Col>
                <Col xs={11} className="bannerText text-center">
                  <h1
                    style={{
                      margin: "0",
                    }}
                  >
                    JAIN SOCIAL GROUP INT. FEDERATION
                  </h1>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    4-O/P, Vijay Chambers, Opp. Dreamland Cinema, Tribhuvan
                    Road, Mumbai - 400 004.
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "12px",
                      fontWeight: "600",
                    }}
                  >
                    Tel.: 022-23870724 | E-mail : office@jsgif.co.in
                  </p>
                  <p
                    className="Tsingle-enter"
                    style={{
                      padding: "0 20px",
                      fontSize: "18px",
                      margin: "0",
                      fontWeight: "600",
                    }}
                  >
                    Eminence Region Chair Finals
                  </p>
                </Col>
              </Row>
            </div>
            <Row>
              <h6
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  marginBottom: "5px",
                }}
              >
                Region - {response.regName}
              </h6>
              <h6
                style={{
                  fontWeight: "700",
                  textTransform: "capitalize",
                  marginBottom: "10px",
                }}
              >
                Group - {response.grpName}
              </h6>
            </Row>
            <Row>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Respondant's name</h3>
                <p className="Tsingle-enter">{response.name}</p>
              </Col>
              <Col style={{ textAlign: "center" }}>
                <h3 className="H3single-enter">Respondant's Email</h3>
                <p className="Tsingle-enter">{response.email}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  No. of total meeting in 21 month from apr 23 to dec 24.
                </h3>
                <p className="Tsingle-enter">{response.meet}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  No. or total eminance form filled in 6 quarters, reward if you
                  have won, how many
                </h3>
                <p className="Tsingle-enter">{response.eminenceForm}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  No. of report in mangalyatra out of 12 with specific page No.
                  & name
                </h3>
                <p className="Tsingle-enter">{response.mangalyatra}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  No. of participation in i.e. 1,2,3,4,5 quarters 2 marks each
                  quarter
                </h3>
                <p className="Tsingle-enter">{response.participation}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  Submission of form A + B & online money transfer FROM TOTAL
                  GROUPS UR REGION HAVE
                </h3>
                <p className="Tsingle-enter">{response.formAB}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  Specific meeting which has enhanced JSG bonding / JSG moment /
                  Suppotting society. (THIS MEETING SHLD NE INITIATED BY YOU AND
                  OTHER SHLD BE IN SUPPORT)
                </h3>
                <p className="Tsingle-enter">{response.splMeet}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  Your attandance is JSGIF if & region progam how many ? (HOW
                  MANY TIMES YOU HAVE REQUESTED OB'S OF JSGIF AND REGION TO
                  VISIT WITH YOU TO GROUP)
                </h3>
                <p className="Tsingle-enter">{response.attendance}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  NO OF BOARD MEETINGS YOU HAVE ARRANGED IN LAST 21 MONTHS [OF
                  OFFFICIAL MEMBERS OF REGION]
                </h3>
                <p className="Tsingle-enter">{response.boardMeet}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  NO OF GROUPS INCREASED IN YOUR TENURE
                </h3>
                <p className="Tsingle-enter">{response.groupsInc}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  YOUR SPECIFIC SPECIAL MEETING YOU THINK IT IS VERY DIFFERENT
                </h3>
                <p className="Tsingle-enter">{response.specSplmeet}</p>
              </Col>
            </Row>

            <Row>
              <Col>
                <h3 className="H3single-enter" style={{ marginTop: "10px" }}>
                  NO OF HOURS AND TRAVELLING DETAIL YOU EXPENDED ON JSG MOVEMENT
                </h3>
                <p className="Tsingle-enter">{response.hours}</p>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegChairFinalsResponse;
